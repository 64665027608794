"use client";

// third party
import { ReactNode } from "react";
import { default as toast, Toaster, ToastBar } from "react-hot-toast";

import { faTimes, faTriangleExclamation, faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import styles from "./index.module.scss";

export default function Notification() {
  return (
    <Toaster
      containerClassName={styles["notifications-container"]}
      gutter={20}
      toastOptions={{ duration: 5000 }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {() => (
            <div
              className={cx(styles.notification, "d-flex", {
                [styles.success]: t.type === "success",
                [styles.error]: t.type === "error",
              })}
            >
              <div className="me-3">
                {t.type === "success" && (
                  <FontAwesomeIcon icon={faCircleCheck} className={styles.icon} color="#007F5F" />
                )}
                {t.type === "error" && (
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className={styles.icon}
                    color="#D72C0D"
                  />
                )}
              </div>
              {typeof t.message === "string" ? (
                <p className="text-style-body1-bold">{t.message}</p>
              ) : (
                (t.message as unknown as ReactNode)
              )}
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => toast.dismiss(t.id)}
                className={styles["close-icon"]}
              />
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
