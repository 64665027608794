import * as productionConfig from "./production";
import * as serverConfig from "./server";
import * as stagingConfig from "./staging";

export const isProduction = process.env.APP_ENV === "production";

export const isServer = !!process.env.KUBERNETES_SERVICE_HOST;

const {
  PETS_API_URL,
  PRACTICES_API_URL,
  TRIAL_API_URL,
  USERS_API_URL,
  FORMS_API_URL,
  VETS_API_URL,
  ORGANIZATIONS_API_URL,
} = isProduction ? productionConfig : stagingConfig;

const {
  PETS_API_URL: SERVER_PETS_API_URL,
  PRACTICES_API_URL: SERVER_PRACTICES_API_URL,
  TRIAL_API_URL: SERVER_TRIAL_API_URL,
  USERS_API_URL: SERVER_USERS_API_URL,
  FORMS_API_URL: SERVER_FORMS_API_URL,
  VETS_API_URL: SERVER_VETS_API_URL,
  ORGANIZATIONS_API_URL: SERVER_ORGANIZATIONS_API_URL,
} = isServer ? serverConfig : isProduction ? productionConfig : stagingConfig;

export {
  PETS_API_URL,
  PRACTICES_API_URL,
  TRIAL_API_URL,
  USERS_API_URL,
  FORMS_API_URL,
  VETS_API_URL,
  ORGANIZATIONS_API_URL,
  SERVER_PETS_API_URL,
  SERVER_PRACTICES_API_URL,
  SERVER_ORGANIZATIONS_API_URL,
  SERVER_TRIAL_API_URL,
  SERVER_USERS_API_URL,
  SERVER_FORMS_API_URL,
  SERVER_VETS_API_URL,
};

export const CLOUDINARY = "https://cdn.anivive.com";

export const HOST_NAME = process.env.HOST_NAME;
export const AUTH_APP = isProduction
  ? "https://auth.anivive.com"
  : "https://auth.staging.anivive.com";
