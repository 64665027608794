"use client";
import { createContext, FC, useContext, useReducer } from "react";

import { initialState, slice, createActions } from "@/redux/header";

const Context = createContext<{
  state: typeof initialState;
  actions?: ReturnType<typeof createActions>;
}>({
  state: initialState,
});

export const useHeader = () => useContext(Context);

const HeaderProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(slice.reducer, initialState);
  return (
    <Context.Provider value={{ state, actions: createActions(dispatch) }}>
      {children}
    </Context.Provider>
  );
};

export default HeaderProvider;
