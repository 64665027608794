export const PETS_API_URL = "http://ms-pets.microservices.svc.cluster.local:3000";

export const PRACTICES_API_URL = "http://ms-practices.microservices.svc.cluster.local:3000";

export const TRIAL_API_URL = "http://ms-trials.microservices.svc.cluster.local:3000";

export const USERS_API_URL = "http://ms-users.microservices.svc.cluster.local:3000";

export const FORMS_API_URL = "http://ms-forms.microservices.svc.cluster.local:3000";

export const VETS_API_URL = "http://ms-vets.microservices.svc.cluster.local:3000";

export const ORGANIZATIONS_API_URL = "http://ms-organizations.microservices.svc.cluster.local:3000";
