"use client";

import ReactDOM from "react-dom";

const preconnectUrls = [
  "https://cdn.anivive.com",
  "https://stats.g.doubleclick.net",
  "https://googleads.g.doubleclick.net",
  "https://www.google.com",
  "https://www.google-analytics.com",
  "https://www.googletagmanager.com",
  "https://www.googleadservices.com",
];

export default function InitHead() {
  preconnectUrls.forEach((url) => ReactDOM.preconnect(url));

  return null;
}
