"use client";

// next
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

// third party
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// constants
import { AUTH_APP, HOST_NAME } from "@/constants";

// services
import { User } from "@/services/api/server/users";

// context
import { useHeader } from "@/contexts/header";

import styles from "../../index.module.scss";

export default function PrimaryHeaderLinks({ user }: { user: User | null }) {
  const { state } = useHeader();
  const [optionsModal, setOptionsModal] = useState(false);

  const pathname = usePathname();

  useEffect(() => {
    window.addEventListener("click", () => setOptionsModal(false));
    return () => {
      window.removeEventListener("click", () => setOptionsModal(false));
    };
  }, [optionsModal]);

  if (!state.sideNavActive) {
    return (
      <div className="align-items-center d-none d-lg-flex">
        <Link href="/studies" className="hover text-style-body1-bold mx-3" draggable={false}>
          View Studies
        </Link>
        <Link href="/for-pet-owners" className="hover text-style-body1-bold mx-3" draggable={false}>
          For Pet Owners
        </Link>
        <Link href="/for-vets" className="hover text-style-body1-bold mx-3" draggable={false}>
          For Veterinarians
        </Link>
        {user ? (
          <>
            <Link href="/my-pets" className="hover text-style-body1-bold mx-3" draggable={false}>
              My Pets
            </Link>
            <div className="d-flex align-items-center position-relative">
              <FontAwesomeIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOptionsModal(!optionsModal);
                }}
                className="d-none d-md-block cursor--pointer ms-3"
                icon={faUser}
                size="lg"
                color="#1A1A1C"
              />
              {optionsModal && (
                <div className={styles["account-popup"]}>
                  <div className={styles.avatar}>
                    {user?.bio?.image ? (
                      <Image
                        src={user.bio.image}
                        alt={user.firstName}
                        fill
                        sizes="10vw"
                        draggable={false}
                      />
                    ) : (
                      <p>
                        {user?.firstName[0]}
                        {user?.lastName[0]}
                      </p>
                    )}
                  </div>
                  <p className="text-center text-style-h5-regular mt-4 clamp--2">
                    {user?.firstName} {user?.lastName}
                  </p>
                  <hr />
                  <a
                    href={AUTH_APP}
                    className="hover text-style-body1-regular text-decoration-none mb-2 me-auto"
                  >
                    My Account
                  </a>
                  <a
                    href="/auth/logout"
                    className="hover text-style-body1-regular text-decoration-none me-auto"
                  >
                    Log Out
                  </a>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <a
              href={`/auth/login?return_to=${HOST_NAME}${pathname}`}
              className="mx-3"
              draggable={false}
            >
              <button type="button" className="primary text-style-body1-bold py-2 px-3">
                Log In
              </button>
            </a>
            {/*<a href="/auth/registration" className="ms-3" draggable={false}>*/}
            {/*  <button type="button" className="primary text-style-body1-bold py-2 px-3">*/}
            {/*    Sign Up*/}
            {/*  </button>*/}
            {/*</a>*/}
          </>
        )}
      </div>
    );
  }
}
