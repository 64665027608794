"use client";
// third party
import cx from "classnames";

// context
import { useHeader } from "@/contexts/header";

// private
import styles from "./index.module.scss";

export default function HamburgerButton() {
  const { state, actions } = useHeader();

  return (
    <button
      id={styles["hamburger-button"]}
      className={cx({ [styles.open]: state.sideNavActive }, { "d-lg-none": !state.sideNavActive })}
      onClick={() => {
        actions?.setSideNav(!state.sideNavActive);
      }}
      aria-label="Toggle Menu"
    >
      <span />
      <span />
      <span />
      <span />
    </button>
  );
}
