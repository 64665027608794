export default function CloudinaryLoader({ src, width = "auto", quality = "auto" }) {
  const removeExt = (src) => src.replace(/\.(png|jpg)$/, "");

  if (src.includes("storage.googleapis")) {
    return src;
  }

  if (src.includes("contentstack")) {
    return `${src}?width=${width}&quality=${quality}&auto=webp`;
  }

  if (src.includes("cdn.anivive.com")) {
    return removeExt(src.replace("f_auto,q_auto", `f_auto,q_${quality},w_${width}`));
  }

  return removeExt(
    `https://cdn.anivive.com/f_auto,w_${width},q_${quality}/${src.replace(/^\//, "")}`,
  );
}
