"use client";

// next
import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";

// third party
import cx from "classnames";

// anivive
import { useBreakpoints, useScroll } from "@anivive/hooks";

// constants
import { AUTH_APP } from "@/constants";

// context
import { useHeader } from "@/contexts/header";

// private
import styles from "./index.module.scss";

export type LinkDef = {
  name: string;
  path: string;
  onClick?: () => void;
  className?: string;
  showActive?: boolean;
};

type Props = {
  user: {
    firstName: string;
    lastName: string;
  } | null;
};

const defaultLinks: LinkDef[] = [
  {
    name: "View Studies",
    path: "/studies",
  },
  {
    name: "For Pet Owners",
    path: "/for-pet-owners",
  },
  {
    name: "For Vets",
    path: "/for-vets",
  },
];

export default function SideNav({ user }: Props) {
  const { state, actions } = useHeader();
  const ref = useRef<HTMLDivElement | null>(null);
  const { current, xs, sm } = useBreakpoints();
  const [header, setHeader] = useState<LinkDef[]>(defaultLinks);

  const isMobile = xs || sm;

  const pathname = usePathname();

  const { disableScroll, enableScroll } = useScroll({
    paddingSelector: "#nav",
  });

  useEffect(() => {
    const authLinks: LinkDef[] = user
      ? [
          {
            name: "My Pets",
            path: "/my-pets",
          },
          {
            name: "My Account",
            path: AUTH_APP,
          },
          {
            name: "Log Out",
            path: "/auth/logout",
          },
        ]
      : [
          {
            name: "Log In",
            path: "/auth/login",
          },
          // {
          //   name: "Sign Up",
          //   path: "/auth/registration",
          // },
        ];

    setHeader([...defaultLinks, ...authLinks]);
  }, [user]);

  useEffect(() => {
    if (state.sideNavActive) {
      disableScroll();
      return () => {
        enableScroll();
      };
    }
  }, [disableScroll, enableScroll, state.sideNavActive]);

  function HeaderElement() {
    return header.map((item) => (
      <li key={item.name}>
        <div className={styles["menu-item"]}>
          <a
            href={item.path}
            className={cx(styles.link, item.className, "hover thin text-style-h5", {
              active: pathname === item.path,
            })}
            onClick={() => actions?.setSideNav(false)}
            draggable={false}
          >
            {item.name}
          </a>
        </div>
      </li>
    ));
  }

  return (
    <nav className={cx(styles["side-nav"], { [styles.active]: state.sideNavActive })}>
      <div
        className={cx(styles.outside, { [styles.hide]: !state.sideNavActive })}
        onClick={() => {
          actions?.setSideNav(false);
        }}
        role="presentation"
      />
      <div
        ref={ref}
        className={cx(styles.menu, { [styles.active]: !isMobile && state.sideNavActive })}
        role="presentation"
        style={
          isMobile
            ? state.sideNavActive
              ? { top: "0" }
              : {
                  top: `-${
                    (!!current && isMobile && ref.current && ref.current.clientHeight + 2) || 1000
                  }px`,
                }
            : undefined
        }
      >
        <div className={styles["nav-content"]}>
          <ul>
            <HeaderElement />
          </ul>
        </div>
      </div>
    </nav>
  );
}
