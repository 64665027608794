import { Dispatch } from "react";

import { createSlice } from "@reduxjs/toolkit";
import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  sideNavActive: false,
};

export const slice = createSlice({
  name: "header",
  reducers: {
    setSideNav(state, action: PayloadAction<boolean>) {
      state.sideNavActive = action.payload;
    },
  },
  initialState,
});

export const createActions = (dispatch: Dispatch<AnyAction>) => {
  const { setSideNav } = slice.actions;

  return {
    setSideNav(value: boolean) {
      dispatch(setSideNav(value));
    },
  };
};
