export const PETS_API_URL = "https://pets.api.anivive.com";

export const PRACTICES_API_URL = "https://practices.api.anivive.com";

export const TRIAL_API_URL = "https://trials.api.anivive.com";

export const USERS_API_URL = "https://users.api.anivive.com";

export const FORMS_API_URL = "https://forms.api.anivive.com";

export const VETS_API_URL = "https://vets.api.anivive.com";

export const ORGANIZATIONS_API_URL = "https://organizations.api.anivive.com";
