import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Cormorant\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"cormorant\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/app/layout.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/@anivive/ui/index.css");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/@egjs/react-flicking/dist/flicking.css");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/@egjs/flicking-plugins/dist/flicking-plugins.css");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/app/preload.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/layout/footer/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/layout/header/components/hamburger-button/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/layout/header/components/primary-links/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/layout/header/components/sidenav/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/layout/header/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/ui/notification/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/contexts/header/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/styles/globals.scss")